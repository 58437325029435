@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

body,
html,
#root {
    background-color: #081830;
    font-family: Inter, Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}