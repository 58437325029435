.flag input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.flag input[type="radio"] + img {
    cursor: pointer;
}

.flag input[type="radio"]:checked + img {
    border: 2px solid black;
    opacity: 1;
    height: 30px;
    object-fit: cover;
}
