.tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    width: 150px;
    color: white;
    font-size: 12px;
    background-color: #192733;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
    top: -8px;
    left: 120%;
}

.hover-text:hover .tooltip-text {
    visibility: visible;
}

.hover-text {
    display: inline;
    position: relative;
    cursor: pointer;
}